import { useEffect } from "react";
import { useCallback } from "react";
import {
  updateDataFormBlockIdContext,
  updateFormDataScriptSrcContext,
  updateFormDataTrackingScriptSrcContext,
  updateTrackingDivContext,
  updateFormDataDivContext,
  updateDynamicsDivContext,
  updateDynaMXFormIDContext,
  updateDynaMXFormAPIURLContext,
  updateDynaMXFormCountryInputIDContext,
  updateDynaMXFormDropdown1IDContext,
  updateDynaMXFormDropdown2IDContext,
  updateDynaMXFormDropdown3IDContext,
  useUiValue,
} from "stores/uiContext";

const useDynamicsForm = () => {
  const [
    {
      dataFormBlockId,
      formDataScriptSrc,
      formDataTrackingScriptSrc,
      trackingDiv,
      formDataDiv,
      dynamicsDiv,
      dynaMXFormID,
      dynaMXFormAPIURL,
      dynaMXCountryInputID,
      dynaMXDropdown1ID,
      dynaMXDropdown2ID,
      dynaMXDropdown3ID,
    },
    dispatch,
  ] = useUiValue();

  useEffect(() => {
    dispatch(updateDataFormBlockIdContext(dataFormBlockId));
  }, []);

  const updateDataFormBlockId = useCallback(
    (id) => {
      dispatch(updateDataFormBlockIdContext(id));
    },
    [dataFormBlockId]
  );
  const updateFormDataScriptSrc = useCallback(
    (src) => {
      dispatch(updateFormDataScriptSrcContext(src));
    },
    [formDataScriptSrc]
  );
  const updateFormDataTrackingScriptSrc = useCallback(
    (src) => {
      dispatch(updateFormDataTrackingScriptSrcContext(src));
    },
    [formDataTrackingScriptSrc]
  );
  const updateTrackingDiv = useCallback(
    (src) => {
      dispatch(updateTrackingDivContext(src));
    },
    [trackingDiv]
  );
  const updateFormDataDiv = useCallback(
    (src) => {
      dispatch(updateFormDataDivContext(src));
    },
    [formDataDiv]
  );
  const updateDynamicsDiv = useCallback(
    (src) => {
      dispatch(updateDynamicsDivContext(src));
    },
    [dynamicsDiv]
  );

  const updateDynaMXFormID = useCallback(
    (id) => {
      dispatch(updateDynaMXFormIDContext(id));
    },
    [dynaMXFormID]
  );
  const updateDynaMXFormAPIURL = useCallback(
    (api) => {
      dispatch(updateDynaMXFormAPIURLContext(api));
    },
    [dynaMXFormAPIURL]
  );
  
  const updateDynaMXFormCountryInputID = useCallback(
    (id) => {
      dispatch(updateDynaMXFormCountryInputIDContext(id));
    },
    [dynaMXCountryInputID]
  );
  const updateDynaMXFormDropdown1ID = useCallback(
    (id) => {
      dispatch(updateDynaMXFormDropdown1IDContext(id));
    },
    [dynaMXDropdown1ID]
  );
  const updateDynaMXFormDropdown2ID = useCallback(
    (id) => {
      dispatch(updateDynaMXFormDropdown2IDContext(id));
    },
    [dynaMXDropdown2ID]
  );
  const updateDynaMXFormDropdown3ID = useCallback(
    (id) => {
      dispatch(updateDynaMXFormDropdown3IDContext(id));
    },
    [dynaMXDropdown3ID]
  );

  return {
    dataFormBlockId,
    formDataScriptSrc,
    formDataTrackingScriptSrc,
    trackingDiv,
    formDataDiv,
    dynamicsDiv,
    dynaMXFormID,
    dynaMXFormAPIURL,
    dynaMXCountryInputID,
    dynaMXDropdown1ID,
    dynaMXDropdown2ID,
    dynaMXDropdown3ID,
    updateDataFormBlockId,
    updateFormDataScriptSrc,
    updateFormDataTrackingScriptSrc,
    updateTrackingDiv,
    updateFormDataDiv,
    updateDynamicsDiv,
    updateDynaMXFormID,
    updateDynaMXFormAPIURL,
    updateDynaMXFormCountryInputID,
    updateDynaMXFormDropdown1ID,
    updateDynaMXFormDropdown2ID,
    updateDynaMXFormDropdown3ID,
  };
};
export default useDynamicsForm;
